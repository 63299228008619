.what-people-say {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 80px auto 0;
  max-width: 1000px;
  gap: 24px;
  text-align: left;

  @media (max-width: 878px) {
    flex-direction: column;
    margin-top: 40px;
  }

  .w-left {
    width: 40%;
    border-radius: 35px;
    background: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    padding: 16px;
    color: #191919;

    @media (max-width: 878px) {
      width: calc(100% - 24px);
      max-width: 400px;
    }

    @media (max-width: 578px) {
      gap: 16px;
    }

    img {
      @media screen and (max-width: 578px) {
        width: 150px;
        height: 150px;
      }
    }

    .name {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 8px;

      @media screen and (max-width: 578px) {
        font-size: 18px;
      }
    }
  }

  .title {
    color: gray;

    @media screen and (max-width: 578px) {
      font-size: 14px;
    }
  }

  .w-right {
    width: 58%;
    color: #fff;
    font-size: 18px;

    @media (max-width: 878px) {
      width: 100%;
      max-width: 600px;
      margin-top: -20px;
      font-size: 16px;
    }

    div {
      font-size: 54px;
      transform: translateY(20px);
    }
  }
}
