.click {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  &:active {
    opacity: 0.8;
  }
}

.no-select {
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

button {
  padding: 16px 40px;
  font-weight: 700;
  font-size: 18px;
  @extend .click;
  border: 2px solid #fff;

  &.full-width {
    width: 100%;
  }

  &.primary {
    background: #f4b659;
    color: #252525;
    border: 2px solid #f4b659;
  }
}

.mt-10 {
  margin-top: 40px !important;
}

.mb-2 {
  margin-bottom: 8px !important;
}

.desktop-max-width {
  max-width: 1300px;
  margin: 0 auto;
}

.App {
  overflow-x: hidden;
}
