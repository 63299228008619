.partners {
  .partners-inner {
    width: fit-content;
    border-radius: 30px;
    max-width: calc(100vw - 128px);
    background: #fff;
    padding: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow-x: scroll;
    gap: 60px;
    margin: -156px auto 96px;
    position: relative;
    z-index: 3;

    @media screen and (max-width: 678px) {
      gap: 30px;
      border-radius: 14px;
      padding: 24px 30px;
      max-width: calc(100vw - 108px);
      margin-top: -132px;
    }

    img {
      max-height: 50px;
      width: auto;

      @media screen and (max-width: 878px) {
        max-height: 40px;
      }

      @media screen and (max-width: 678px) {
        max-height: 30px;
      }
    }
  }
}
