.services-main {
  margin-top: 60px;

  @media screen and (max-width: 678px) {
    margin-top: 40px;
  }

  .items-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;

    &.extra-space {
      gap: 32px;
    }

    &:not(:last-child) {
      margin-bottom: 46px;
    }

    & > div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: white;
      gap: 16px;

      @media screen and (max-width: 678px) {
        flex-direction: column;
      }

      .desc {
        width: fit-content;
        max-width: 200px;
        text-align: left;
        font-size: 20px;
        font-weight: 600;

        @media screen and (max-width: 868px) {
          font-size: 16px;
        }

        @media screen and (max-width: 678px) {
          max-width: 150px;
          text-align: center;
        }

        @media screen and (max-width: 478px) {
          font-size: 14px;
        }
      }
    }

    img {
      width: 120px;
      height: auto;

      @media screen and (max-width: 868px) {
        width: 80px;
      }

      @media screen and (max-width: 480px) {
        width: 60px;
      }
    }
  }
}
