.landing-page {
  .banner {
    position: relative;
    overflow: hidden;
    background: #252525;
    padding: 24px;
    color: white;
    height: auto;
    height: calc(100vh - 93px);
    min-height: 800px;
    max-height: 800px !important;
    padding-top: 123px;
    padding-bottom: 0;

    @media (max-width: 878px) {
      height: auto;
      max-height: unset !important;
      padding-top: 100px;
    }

    .banner-inner {
      @media screen and (max-width: 878px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px;
        width: 100%;
        height: auto;
      }

      .text-content {
        .c-row-one {
          font-size: 180px;
          line-height: 190px;
          font-weight: 700;
          margin-bottom: 32px;
          max-width: 900px;

          @media (max-width: 878px) {
            font-size: 100px;
            line-height: 110px;
          }

          @media (max-width: 600px) {
            font-size: 60px;
            line-height: 70px;
          }
        }

        .c-row-two {
          font-size: 20px;
          margin-bottom: 34px;
          color: rgb(148, 148, 148);
          max-width: 750px;

          @media (max-width: 878px) {
            font-size: 16px;
          }
        }
      }

      .banner-image {
        position: absolute;
        right: 0;
        top: 100px;
        bottom: 0;
        width: auto;
        max-width: 100vw;
        height: auto;
        transform: translateY(12px);

        @media (max-width: 878px) {
          position: relative;
          width: 100%;
          max-width: 500px;
          top: 20px;

          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }

  .proven-expertise {
    .section-desc {
      max-width: 800px;
    }
  }

  .newsletter {
    .input-row {
      margin-top: 34px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 4px;

      @media screen and (max-width: 678px) {
        flex-direction: column;
        gap: 12px;
      }

      input {
        width: 300px;
        background: transparent;
        color: white;
        border: 1px solid #f4b659;
        padding-left: 12px;

        @media screen and (max-width: 678px) {
          width: calc(100% - 12px);
          height: 40px;
        }

        &:focus-visible {
          outline: none;
        }
      }
    }
  }

  .contact {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 24px;
    padding-bottom: 0px;
    padding-top: 50px;
    overflow: hidden;

    @media screen and (max-width: 878px) {
      flex-direction: column-reverse;
      padding-top: 0;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .c-left {
      width: 60%;
      max-width: 400px;

      @media screen and (max-width: 878px) {
        width: 100%;
        max-width: 300px;
      }

      img {
        width: 100%;
        height: auto;
        transform: translateY(10px);
      }
    }

    .c-right {
      width: 40%;
      padding-top: 24px;

      @media screen and (max-width: 878px) {
        width: 100%;
        margin-top: 24px;
      }

      .c-row-one {
        color: #fff;
        font-size: 60px;
        font-weight: 700;
        margin-bottom: 45px;

        @media screen and (max-width: 878px) {
          font-size: 40px;
          margin-bottom: 35px;
        }

        @media screen and (max-width: 578px) {
          font-size: 30px;
        }
      }
    }
  }

  section {
    padding: 96px 24px;
    background: #191919;

    &.lighter {
      background: #252525;
    }

    .section-title {
      color: #fff;
      font-size: 60px;
      font-weight: 700;
      margin-bottom: 32px;

      @media screen and (max-width: 878px) {
        font-size: 40px;
      }
    }

    .section-desc {
      color: rgb(148, 148, 148);
      font-size: 20px;
      margin: 0 auto;

      @media screen and (max-width: 878px) {
        font-size: 16px;
      }
    }
  }
}

.center {
  text-align: center;
}

.divider {
  border-top: 2px solid #f4b659;
  margin: 100px auto;
  max-width: 600px;

  @media (max-width: 878px) {
    margin: 50px auto;
  }
}
