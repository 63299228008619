.contact-us {
  padding: 170px 24px 100px;
  background: #252525;
  color: #fff;

  @media screen and (max-width: 568px) {
    padding: 120px 24px 50px;
  }

  .c-title {
    font-size: 56px;
    font-weight: 700;
    margin-bottom: 48px;

    @media screen and (max-width: 568px) {
      font-size: 36px;
    }

    span {
      color: #f4b659;
    }
  }

  .c-form {
    border-radius: 25px;
    background: #191919;
    max-width: 800px;
    margin: 0 auto;
    padding: 48px 24px;

    @media screen and (max-width: 568px) {
      padding: 24px;
      border-radius: 16px;
    }

    .form-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 24px;

      @media screen and (max-width: 568px) {
        flex-direction: column;
        gap: 15px;
        margin-bottom: 16px;
      }

      .form-item {
        width: 48%;

        &.full {
          width: 100%;
        }

        @media screen and (max-width: 568px) {
          width: 100%;
        }

        input,
        textarea {
          width: calc(100% - 12px);
          height: 57px;
          background: transparent;
          color: white;
          border: 1px solid #f4b659;
          padding-left: 12px;
          font-size: 20px;

          @media screen and (max-width: 568px) {
            font-size: 16px;
          }

          &:focus-visible {
            outline: none;
          }
        }

        textarea {
          height: 200px;
          padding-top: 12px;
        }

        p {
          color: #f47359;
        }
      }
    }
  }
}

.contact-info {
  background: #1f1f1f;
  padding: 50px 24px;
  color: white;

  .i-inner {
    display: flex;
    flex-direction: row;
    max-width: 1000px;
    margin: 0 auto;
    justify-content: space-between;
    gap: 24px;
    flex-wrap: wrap;

    .c-group {
      min-width: 200px;

      .g-title {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 12px;
      }
    }
  }
}
