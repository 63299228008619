.about-us {
  .banner {
    overflow: hidden;
    background: #252525;
    padding: 24px;
    color: white;
    padding-top: 153px;
    padding-bottom: 70px;

    @media (max-width: 878px) {
      padding-top: 100px;
      padding-bottom: 40px;
    }

    .text {
      font-size: 76px;
      font-weight: 700;
      max-width: 1140px;
      margin: 0 auto;
      color: rgba(255, 255, 255, 0.25);
      position: relative;

      @media screen and (max-width: 868px) {
        font-size: 60px;
      }

      @media screen and (max-width: 700px) {
        font-size: 45px;
      }

      @media screen and (max-width: 568px) {
        font-size: 32px;
      }
    }

    .aristotle {
      position: absolute;
      left: 40%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 40vw;
      max-width: 500px;

      @media (max-width: 878px) {
        max-width: 300px;
      }

      @media screen and (max-width: 568px) {
        width: 60vw;
        max-width: 200px;
      }
    }
  }

  .team {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 48px;

    @media (max-width: 878px) {
      flex-direction: column;
      align-items: center;
      gap: 24px;
    }

    .member {
      border-radius: 35px;
      background: #191919;
      padding: 16px;
      width: 45%;
      max-width: 300px;

      @media (max-width: 878px) {
        width: 100%;
      }

      img {
        background: white;
        border-radius: 40px;
        width: 100%;
        height: auto;
      }

      .m-name {
        font-size: 24px;
        font-weight: 700;
        margin-top: 16px;
        margin-bottom: 4px;
      }

      .m-title {
        color: #dedede;
      }
    }
  }

  .join-us {
    position: relative;
    padding-top: 150px !important;

    @media (max-width: 878px) {
      padding-top: 100px !important;
    }

    img {
      position: absolute;
      right: 50%;
      top: -80px;
      transform: translateX(50%);
      max-width: 350px;
      height: auto;

      @media (max-width: 778px) {
        max-width: 250px;
      }
    }
  }

  .about-row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 48px;

    &.reverse {
      flex-direction: row-reverse;
    }

    @media (max-width: 878px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
    }

    .img-container {
      width: 50%;
      max-width: 500px;

      &.head {
        max-width: 400px;
      }

      @media (max-width: 878px) {
        width: 100%;
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  section {
    padding: 96px 24px;
    background: #191919;
    color: white;

    @media (max-width: 878px) {
      padding: 48px 24px;
    }

    &.team-s {
      @media (max-width: 878px) {
        padding-bottom: 96px;
      }
    }

    &.lighter {
      background: #252525;
    }

    .s-text {
      max-width: 600px;
    }

    .s-title {
      font-size: 56px;
      font-weight: 700;
      margin-bottom: 24px;
      max-width: 500px;

      @media screen and (max-width: 568px) {
        font-size: 28px;
      }

      &.max-md {
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
      }

      span {
        color: #f4b659;
      }
    }

    .s-desc {
      font-size: 20px;
      margin-bottom: 34px;
      color: rgb(148, 148, 148);
      max-width: 750px;

      @media screen and (max-width: 568px) {
        font-size: 16px;
      }

      &.max-md {
        max-width: 1000px;
        margin: 0 auto;
      }
    }
  }
}
